import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { css, useTheme } from "@emotion/react"
import { mediaQuery } from "../utils/mediaQuery"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const linkStyles = css`
  text-decoration: none;
  color: inherit;

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

const Contact = () => {
  const data = useStaticQuery(graphql`
    {
      file(name: { eq: "contact-map" }) {
        name
        childImageSharp {
          gatsbyImageData(
            tracedSVGOptions: { color: "#000000", background: "#ffffff" }
            placeholder: TRACED_SVG
            layout: FULL_WIDTH
          )
        }
      }
    }
  `)

  const theme = useTheme()

  return (
    <Layout>
      <Seo title="Contact" />
      <section
        css={css`
          min-height: 75vh;
          text-align: center;

          ${mediaQuery[1]} {
            margin-top: ${theme.heights.header};
            padding: 20px 2% auto;
          }
        `}
      >
        <div
          css={css`
            display: grid;
            grid-template-columns: 1fr;

            ${mediaQuery[1]} {
              grid-template-columns: 2fr 1fr;
            }

            ${mediaQuery[2]} {
              grid-template-columns: 1fr 1fr;
              padding: 20px 10%;
            }
          `}
        >
          <div>
            <GatsbyImage
              image={data.file.childImageSharp.gatsbyImageData}
              alt={data.file.name}
            />
          </div>
          <div
            css={css`
              text-align: left;
              padding-left: 20px;

              ${mediaQuery[1]} {
                padding-right: 20px;
                text-align: right;
              }

              ${mediaQuery[2]} {
                margin: auto;
              }
            `}
          >
            <h2>Contact us</h2>
            <h3>Switzerland:</h3>
            <a css={linkStyles} href={`mailto:harry@tujofilms.com`}>
              <p>harry@tujofilms.com</p>
            </a>
            <a css={linkStyles} href={"tel:0041764543230"}>
              <p>0041 764543230</p>
            </a>
            <h3>London:</h3>
            <a css={linkStyles} href={`mailto:sam@tujofilms.com`}>
              <p>sam@tujofilms.com</p>
            </a>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Contact
